import './App.css';
import logo from "./Images/Mash-Ramezoon-Icon.png"
import insta from "./Images/InstagraLogo.png"
import telegram from "./Images/telegramLogo.png"
import bazaar from "./Images/Bazaar.png"
import linkdin from "./Images/linkdinLogo.png"
import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LandingPage from  "./Pages/LandingPage.js"
import Helps from './Pages/Helps';
import Article from './Pages/Article.js';
import PlayerMessage from './Pages/PlayerMessaage';
import AdminPage from './Pages/AdminPage';
import MessagesPage from './Pages/MessagesPage';



function App() {
  return (
    <div className='App'>
      <nav class="navbar navbar-expand-lg navbar-light bg-light" dir="rtl">
        <div class="container-fluid">
          <a class="navbar-brand" href="/">
            <img src={logo} alt='...' height="40"/>
          </a>
          <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo02" aria-controls="navbarTogglerDemo02" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo02">
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link" aria-current="page" href="/helps">راهنما</a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="/messages">نظرات</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<LandingPage/>} />
          <Route path="/helps" element={<Helps/>} />
          <Route path="/article" element={<Article/>} />
          <Route path="/player-message" element={<PlayerMessage/>}/>
          <Route path="/admin" element={<AdminPage/>}/>
          <Route path="/messages" element={<MessagesPage/>}/>
        </Routes>
      </BrowserRouter>
      <footer className='App-footer'>
        <div className="container">
          <div className="row">
            <div className="col-md-4"/>
            <div className="col-md-4 col-sm-12 App-footer-icon">
              <div className="row">
                <div className="col">
                  <a href="">
                    <img src={insta} width="38" height="38"/>
                  </a>
                </div>
                <div className="col">
                  <a href="">
                    <img src={telegram} width="38" height="38"/>
                  </a>
                </div>
                <div className="col">
                  <a href="">
                    <img src={bazaar} width="38" height="38"/>
                  </a>
                </div>
                <div className="col">
                  <a href="">
                    <img src={linkdin} width="38" height="38"/>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md-4"/>
          </div>
          <div className="row App-footer-caption">
                هاب بازی‌سازی دانشگاه علم و صنعت ایران
          </div>
        </div>
      </footer>
    </div>   
  );
}

export default App;
