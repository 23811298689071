import React, { useState } from "react";
import {useRef} from 'react';
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import "./PlayerMessaage.css";
import axios from "axios";


export default function PlayerMessage(props){
    const [searchParams, setSearchParams]=useSearchParams();
    const [player, setPlayer]=useState(searchParams.get('player'));
    const [messege, setMessage]=useState("");
    const [ba,setBa]=useState(true);
    const ref = useRef(null);

    function delay(time) {
        return new Promise(resolve => setTimeout(resolve, time));
      }

    const handleMessageChange =async event => {
        
        setMessage(ref.current.value);
        await delay(1000);
        console.log("msg"+JSON.stringify({"comment":ref.current.value}));
        if(ref.current.value!=""){
        axios.post('http://45.159.197.151:8081/comments/add-comment/', {"comment":ref.current.value},{headers:{'Authorization': `Bearer ${JSON.parse(player)[1]}`}}).then(response => console.log("mmd"+response.data.message));;
        setBa(false);
        }
        
      };


    return <div className="PlayerMessageWholeMessageBox">
        <div className="container PlayerMessageBox">
            <div className="row PlayerMessageContent">
                پیام
            </div>
            <div className="row PlayerMessageAuthor">
                فرستنده: {JSON.parse(player)[0]}
            </div>
            <div className="row PlayerMessageMessageTitle">
                پیام:
                <textarea ref={ref} className="PlayerMessageInput" title="lln">

                </textarea>
            </div>
            <div className="row PlayerMessageSendContainer">
                {ba?
                <button onClick={handleMessageChange} className="PlayerMessageSendButton">
                    ارسال
                </button>
                :<div>با تشکر از نظر شما</div>
                }
            </div>
        </div>
    </div>
}