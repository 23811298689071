import React, { useState } from "react";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import "./Article.css"
import { Collapse } from '@mui/material';

function Article(props) {
    
    const Art1=[
        ["اجرا نشدن در حالت افقی","اجرا نشدن در حالت افقی","بازی در حالت افقی برای مش رمضون در دسترس نیست زیرا این بخشی از طراحی اصلی بازی نبود."],
        ["آیا می توان با چندین اکانت روی یک دستگاه بازی کرد؟","آیا می توان با چندین اکانت روی یک دستگاه بازی کرد؟","تنها یک حساب مهمان برای یک دستگاه موجود است، اما می توانید با بسیاری از حساب های فیس بوک وارد شوید و بین آنها جابجا شوید.همچنین می توانید برای ادامه بازی با حساب مهمان از سیستم خارج شوید. ما به شما توصیه می کنیم از یک حساب فیس بوک برای ایمن نگه داشتن اطلاعات بازی خود استفاده کنید."],
        ["چگونه می توانم مش رمضون را از رایانه خود بازی کنم؟","چگونه می توانم مش رمضون را از رایانه خود بازی کنم؟","مش رمضون برای رایانه ها در دسترس نیست، شما فقط می توانید از دستگاه های تلفن همراه پشتیبانی شده مانند تلفن های هوشمند و تبلت ها بازی کنید."],
        ["چگونه می توانم سطوحی را که قبلاً پاس کرده ام بازی کنم؟","چگونه می توانم سطوحی را که قبلاً پاس کرده ام بازی کنم؟","در مش رمضون وقتی از یک سطح عبور می کنی، دیگر راه برگشتی نیست. متأسفانه امکان بازی مجدد در سطحی که قبلاً گذرانده اید وجود ندارد. هزاران سطح در مش رمضون وجود دارد و اشیاء جدید به طور معمول اضافه می شوند."],
        ["سطح خیلی سخت است/زمان کافی نیست","سطح خیلی سخت است/زمان کافی نیست","برخی از سطوح ممکن است سخت به نظر برسند، اما همیشه می توان آنها را بدون خرید زندگی، سکه یا تقویت کننده تکمیل کرد!"]
    ];
    const Art2=[
        ["آیا برای بازی مش رمضون باید پول واقعی خرج کنم؟","آیا برای بازی مش رمضون باید پول واقعی خرج کنم؟","مش رمضون یک بازی کاملا رایگان است. تمام سطوح به گونه ای طراحی شده اند که بدون صرف هزینه تکمیل شوند. برای بازی سطوح نیازی به خرج کردن پول واقعی ندارید."],
        ["چگونه سکه بخریم؟","چگونه سکه بخریم؟","می توانید سکه را از صفحه فروشگاه خریداری کنید. بسته یا مقدار سکه ای را که می خواهید خریداری کنید انتخاب کنید و سپس روی دکمه ضربه بزنید. مراحل پرداخت کافه بازار را ادامه دهید. پس از تایید پرداخت شما توسط کافه بازار، بلافاصله سکه های خود را خواهید داشت."],
        ["چگونه می توانم از خریدهای درون برنامه ای تصادفی جلوگیری کنم؟","چگونه می توانم از خریدهای درون برنامه ای تصادفی جلوگیری کنم؟","مش رمضون یک بازی کاملا رایگان است اما شما می توانید با استفاده از پول واقعی آیتم ها/سکه های درون بازی را خریداری کنید.        اگر نمی خواهید به طور تصادفی چیزی بخرید، می توانید خریدهای خود را از گزینه های دستگاه خود محدود کنید."]
    ];
    const Art3=[
        ["مسابقات تیمی چیست؟","مسابقات تیمی چیست؟","تورنمنت تیمی یک رویداد تیمی است که فقط بازیکنان درون یک تیم می توانند در آن شرکت کنند و شرکت کنند.بازیکنان می توانند با ضربه زدن روی نماد منوی اصلی Team Tournament (نماد شمشیرهای بنفش و طلایی) به مسابقات تیم بپیوندند. همچنین می توانید با مشاهده نماد، پیشرفت تیم خود را در طول رویداد بررسی کنید."]
    ];
    const Art4=[
        ["چگونه می توانم یک نام مستعار وارد کنم؟","چگونه می توانم یک نام مستعار وارد کنم؟","وقتی بازی را شروع کنید یک نام مستعار به شما می دهد. این نام مستعار یک مکان نگهدار است و به طور تصادفی مانند بازیکن ۱۲۳ تولید می شود.        می توانید نام مستعار خود را در اولین بازدید از Leaderboard یا Star Tournament وارد کنید.همچنین می توانید نام مستعار خود را در هر زمان از دکمه تنظیمات وارد کنید و روی نام مستعار خود ضربه بزنید.فراموش نکنید که می توانید نام مستعار خود را یک بار وارد کنید و بعداً نمی توانید نام مستعار خود را تغییر دهید."],
        ["چگونه می توانم نام مستعار خود را تغییر دهم؟","چگونه می توانم نام مستعار خود را تغییر دهم؟","شما می توانید یک بار نام خود را تغییر دهید، بنابراین مطمئن شوید که نام خود را به درستی وارد کرده اید.وقتی صفحه قهرمانان یا ستاره مسابقات را بررسی می کنید، بازی از شما می خواهد که یک نام مستعار وارد کنید.        همچنین می توانید نام مستعار خود را از دکمه تنظیمات تغییر دهید، روی نام فعلی خود ضربه بزنید تا آن را تغییر دهید."]
    ];
    const Art5=[
        ["چگونه می توانم به یک تیم ملحق شوم؟","چگونه می توانم به یک تیم ملحق شوم؟","وقتی به سطح 6 رسیدید، می‌توانید تب Team را در منوی اصلی ببینید.می توانید در آنجا به یک تیم پیشنهادی بپیوندید یا می توانید از دکمه جستجو برای پیدا کردن و پیوستن به یک تیم استفاده کنید.اگر یک نوع تیم بسته است، باید منتظر تایید رهبر تیم برای پیوستن باشید."],
        ["چگونه یک تیم را ترک کنیم؟","چگونه یک تیم را ترک کنیم؟","می‌توانید تیم خود را از ضربه زدن روی «تیم من» رها کنید، سپس روی دکمه «اطلاعات» ضربه بزنید و «ترک» را بزنید.فراموش نکنید که با ترک یک تیم هیچ پیشرفتی را از دست نخواهید داد."],
        ["من نمی خواهم به یک تیم ملحق شوم، چه کاری می توانم انجام دهم؟","من نمی خواهم به یک تیم ملحق شوم، چه کاری می توانم انجام دهم؟","بازیکنان مش رمضون می توانند به یک تیم بپیوندند زیرا بازیکنان می توانند زندگی را به صورت رایگان ارسال و دریافت کنند. همچنین تیم سینه یک تقویت عالی برای همه اعضا است و دریافت آن نسبتاً آسان است. اگر نمی خواهید در تیم خود باشید، همیشه می توانید آن را ترک کنید. با ضربه زدن روی تیم من سپس روی دکمه اطلاعات ضربه بزنید و ترک را بزنید."],
        ["من از یک تیم اخراج شدم، چرا؟","من از یک تیم اخراج شدم، چرا؟","رهبر تیم کسی است که درخواست‌های تیم را رد/می‌پذیرد و همچنین دارای اختیار لگد زدن به بازیکن است. بنابراین ممکن است دلایل زیادی مانند عدم فعالیت و غیره وجود داشته باشد. همچنین ممکن است یک رهبر به طور تصادفی شما را لگد زده باشد. پس از اخراج شما، امکان پیوستن مجدد به یک تیم وجود ندارد."],
        ["من از یک تیم اخراج شدم/رد شدم، آیا می توانم دوباره به همان تیم ملحق شوم؟","من از یک تیم اخراج شدم/رد شدم، آیا می توانم دوباره به همان تیم ملحق شوم؟","رهبر تیم کسی است که درخواست‌های تیم را رد یا می‌پذیرد همچنین مجوز ضربه زدن را دارد. اگر بازیکنی از یک تیم لگد/رد شود، امکان عضویت مجدد وجود ندارد."]
    ];
    const Art6=[
        ["مشکلات ارتباطی","مشکلات ارتباطی","گاهی اوقات یک اتصال آهسته ممکن است بازی را از به‌روزرسانی اطلاعات مهم باز دارد. حتی شبکه‌های معمولی سریع نیز می‌توانند هر از گاهی با مشکلات اتصال مواجه شوند. توصیه می‌کنیم در صورت امکان با اتصال سریع و پایدار بازی کنید."],
        ["شامل یک اسکرین شات در مخاطب پشتیبانی شما","شامل یک اسکرین شات در مخاطب پشتیبانی شما","اگر چیزی روی صفحه‌نمایش درست به نظر نمی‌رسد یا گمان می‌کنید که ممکن است با خطای نمایش مواجه شده باشید، اغلب می‌تواند مفید باشد که هنگام تماس با تیم ما یک اسکرین شات از آنچه می‌بینید به اشتراک بگذارید/گرفته کنید و آن را به اشتراک بگذارید."],
        ["به اشتراک گذاری یک ویدیو از یک آگهی که کار نمی کند","به اشتراک گذاری یک ویدیو از یک آگهی که کار نمی کند","اگر به غیر از گرفتن اسکرین شات از پنجره آگهی، با مشکلی در تبلیغات مواجه شدید که به درستی کار نمی کند، می توانید از رفتار آگهی فیلم بگیرید و این پیوست را به بلیط پشتیبانی خود اضافه کنید. به این ترتیب می‌توانیم ویدیو را مشاهده کنیم تا به ما کمک کند جزئیات آگهی را که باید با شرکای تبلیغاتی خود به اشتراک بگذاریم شناسایی کنیم. به عنوان مثال، محصول تبلیغ، مدت زمانی که تبلیغ بدون گزینه X برای بستن آن روی صفحه می ماند (30 ثانیه، 60 ثانیه و غیره)."],
        ["در حال به روز رسانی نسخه برنامه شما","در حال به روز رسانی نسخه برنامه شما","چند دلیل وجود دارد که ما به روز رسانی بازی را توصیه می کنیم. انجام این کار راهی برای دسترسی شما به بسته های اقلام جدید به محض در دسترس شدن است. همچنین، این بسیار تشویق می‌شود، زیرا گاهی اوقات به‌روزرسانی‌ها برای تصحیح مسائلی که طبق انتظار یا طراحی‌شده کار نمی‌کردند، تغییراتی در کدنویسی دارند. بنابراین اگر در بازی با مشکلاتی روبرو هستید، پیشنهاد می‌کنیم بررسی کنید که آیا به‌روزرسانی برای برنامه به عنوان اولین مورد وجود دارد یا خیر. شما می توانید سعی کنید مشکل را برطرف کنید."]
    ];
    const Arts=[Art1,Art2,Art3,Art4,Art5,Art6]
    const [tabPointer,setTabPointer]=useState(0);
    const [searchParams, setSearchParams]=useSearchParams();
    const [query, setQuery]=useState(searchParams.get('query'));
    const [open, setOpen] = useState(false);
      return <div>
        <div className="Article-Tabs-Container">
            <div className="container">
                
                        <div className="d-block d-md-none">
                            <button className="Article-Collapse-Button"  onClick={()=>setOpen(!open)}>
                                <div className="container ">
                                    <div className="row">
                                        <div className="col-10 t1">
                                        مقالات این دسته
                                        </div>
                                        <div className="col-2">
                                            
                                        </div>    
                                        
                                    </div>
                                </div>
                            </button>
                            {open? <div className="dropDownShape">
                                {
                                Arts[query].map((anObjectMapped, index) => {
                                return (
                                    <button className={tabPointer==index ? "Article-Active-Tab" : "Article-Tab" } onClick={() =>setTabPointer(index)}>
                                        {anObjectMapped[0]}
                                    </button>
                                );
                            })
                            }
                            </div>:<div></div>}

                            <div className=" col-md-10 Article-Article-Body">
                                <div className="Article-Contents">
                                    {Arts[query][tabPointer][1]}
                                </div>
                            <div className="Article-Text">
                                {Arts[query][tabPointer][2]}
                            </div>
                        </div>
                        </div>
                        
                
                <div className="d-none d-md-block"> 
                <div className="row">
                         
                    <div className="col-sm-0 col-md-2">
                        <div className="row Article_Group_Article">
                        مقالات این دسته
                        </div>
                        
                        
                        {
                            Arts[query].map((anObjectMapped, index) => {
                                return (
                                    <button className={tabPointer==index ? "Article-Active-Tab" : "Article-Tab" } onClick={() =>setTabPointer(index)}>
                                        {anObjectMapped[0]}
                                    </button>
                                );
                            })
                        }
                    </div>
                        
                    
                    
                    <div className=" col-md-10 Article-Article-Body">
                        <div className="Article-Contents">
                            {Arts[query][tabPointer][1]}
                        </div>
                        <div className="Article-Text">
                            {Arts[query][tabPointer][2]}
                        </div>
                    </div>
                
                </div>
                </div>
            </div>
        </div>
      </div>;
  }

export default Article