import React, { useState } from "react";
import {useRef} from 'react';
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import "./AdminMessages.css"
import axios from "axios";

function delay(time) {
    return new Promise(resolve => setTimeout(resolve, time));
  }

function AdminMessages(props) {
    const [messege, setMessage]=useState("");
    const ref = useRef(null);
    const [ba,setBa]=useState(true);
    

     const handleMessageChange =async event => {
        
        setMessage(ref.current.value);
        await delay(1000);
        console.log("msg"+JSON.stringify({"commentId":props.number,"reply":ref.current.value}));
        axios.post('http://45.159.197.151:8081/comments/reply-comment/', {"commentId":props.number,"reply":ref.current.value}).then(response => console.log("mmd"+response.data.message));;
        setBa(false);
        
      };
    
      return <div className="AdminMessageWhole">
        <div className="AdminMessageBox">
            <div className="AdminMessageNumber">
                پیام {props.number+1}
            </div>
            <div className="AdminMessageSender">
                فرستنده : {props.senderName}
            </div>
            <div className="AdminMessageSender">
                پیام: 
            </div>
            <div className="AdminMessageMessage">
                {props.message}
            </div>
            <div className="AdminMessageSender">
                پاسخ: 
            </div>
            <textarea ref={ref} className="AdminMessageReply"/>
            <br/>
            {ba? 
            <button onClick={handleMessageChange} className="AdminMessageSendButton">
                ارسال پاسخ
            </button>:<div/>}
        </div>
      </div>;
  }

export default AdminMessages