import React from "react";
import "./Helps.css"
import background from "../Images/background.png"
import { Link } from "react-router-dom";

class Helps extends React.Component {
    render() {
      return <div>
        <img src={background} alt="..." width="100%"/>
        <div className="Helps-textContainer">
          <div className="container">
            <div className="row">
                <div className="col-sm-12 col-md-4 Helps-ButtonContainer">
                    <a href="/article/?query=0">
                    <button className="Helps-Button">
                        راهنمای بازی
                        
                    </button>
                    </a>
                </div>

                <div className="col-sm-12 col-md-4 Helps-ButtonContainer">
                <a href="/article/?query=1">
                    <button className="Helps-Button">
                         خرید درون برنامه ای
                    </button>
                </a>
                </div>

                <div className="col-sm-12 col-md-4 Helps-ButtonContainer">
                    <a href="/article/?query=2">
                        <button className="Helps-Button">
                            ایونت‌ها
                        </button>
                    </a>
                </div>

                <div className="col-sm-12 col-md-4 Helps-ButtonContainer">
                    <a href="/article/?query=3">
                        <button className="Helps-Button">
                            حساب کاربری
                        </button>
                    </a>
                </div>

                <div className="col-sm-12 col-md-4 Helps-ButtonContainer">
                    <a href="/article/?query=4">
                        <button className="Helps-Button">
                            تیم ها
                        </button>
                    </a>
                </div>

                <div className="col-sm-12 col-md-4 Helps-ButtonContainer">
                    <a href="/article/?query=5">
                        <button className="Helps-Button">
                            بخش فنی
                        </button>
                    </a>
                </div>
            </div>
          </div>
        </div>
      </div>;
    }
  }

export default Helps