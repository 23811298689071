import React, { useState } from "react";
import { useLocation, useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import "./AdminMessages.css"


function Message(props) {
      return <div className="AdminMessageWhole">
        <div className="AdminMessageBox">
            <div className="AdminMessageNumber">
                پیام {props.number}
            </div>
            <div className="AdminMessageSender">
                فرستنده : {props.senderName}
            </div>
            <div className="AdminMessageSender">
                پیام: 
            </div>
            <div className="AdminMessageMessage">
                {props.message}
            </div>
            <div className="AdminMessageSender">
                پاسخ: 
            </div>
            <div className="AdminMessageReplyMessages">
                {props.reply}
            </div>
            <br/>
        </div>
      </div>;
  }

export default Message