import React from "react";
import "./LandingPage.css"
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import background from "../Images/background.png"
import cafe from "../Images/download.png"

class LandingPage extends React.Component {
    render() {
      return <div>
        <img src={background} alt="..." width="100%"/>
        <div className="LandingPage-textContainer">
          مش رمضون یکی از انباردار های قدیمی و معتمد تهرانه.
          <br/>
          چند وقتی هست که انبار مش رمضون کلی بهم ریخته و خود مشتی باید زودتر انبارشو مرتب کنه.
          مش رمضون تو انبارش از هر چیزی دوتا داره.
          <br/>
          اما جدا از این کار سر مشتی کلی کار دیگه ریخته و نمیرسه انبار به اون بزرگی رو مرتب کنه.
           برای همین میخواد بیاد پیش شما و از شما بخواد که در قبال گرفتن ستاره، بهش تو مرتب کردن انبارش کمک کنی.
          <br/>
          الان فقط و فقط یه کار داری. اینکه خرت و پرت‌های انبار مش رمضون رو دوتا دوتا کنارهم بذاری.
          <br/>
          پس منتظر چی هستی! بدو کارتو شروع کن!
          <br/>
          موفق باشی!!!
          <div className="downloadContainer">
          <a href="https://cafebazaar.ir/app/com.JellyLandStudio.MashRamezoon?l=en" >
            <img src={cafe} width="300" height="100"/>
          </a>
          </div>
        </div>

      </div>;
    }
  }

export default LandingPage