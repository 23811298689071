import React from "react";
import "./AdminPage.css"
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider } from "@mui/system";
import AdminMessages from "./AdminMessages";
import axios from "axios";


class AdminPage extends React.Component {
    messages=[
        ["0","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["1","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["2","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["3","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["4","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["5","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["6","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["7","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["8","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["9","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["10","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["11","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["12","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["13","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"],
        ["14","خایخبزخصهدب صثبتخحصثبخحتصثخحبت صحخثتبحخثصتبخحتثصخحبت حخصثتبخحپصخحزیپخحصثتیخحصثبی حثدتبتخحصتیخحزپخحثصتیخحث حخصثدتبخحصتیخحزپصخحیزتخحثصی صثدتیخحزصثخحزخثصحنبپخحثصی خحصتیحخصثپخحیپثصخحی حخثصدبخحثصپبییخحثصپبمحکمثصپیزخحپث حدثصحبپزخحصثبپثصپبزخحصحث"]
    ];

    constructor(props) {
        super(props);
        this.state = {
            currentPage:1,
            startMessage:0,
            endMessage:5,
            currentMessage:0
        };
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
    }

    async componentDidMount() {
        axios.get(`http://45.159.197.151:8081/comments/admin-comments/`)
          .then(res => {
            const commentss=res.data;
            console.log("comments:"+JSON.stringify(commentss.result));
            this.setState({
                comments: commentss
            })
          })
          
          
      }
    
    render() {
        
        const handleMessageChange = (event,value) => {
            this.setState({
                currentPage:value,
                startMessage:(value-1)*6,
                endMessage:(value)*6 -1
            })
          };
          this.setState({currentMessage:[]})
          try{
        return  <div>
            {
                 this.state.comments.result.slice(this.state.startMessage,this.state.endMessage).map((anObjectMapped, index) => {
                    return (
                        <AdminMessages number={this.state.startMessage+index} senderName={anObjectMapped.reporter} message={anObjectMapped.report}/>
                    );
                    
                })
            }
            <div className="row">
                <Stack spacing={2} className="AdminPagination">
                    <Pagination onChange={handleMessageChange} count={parseInt(this.state.comments.length/6 + 1)} color="success" />
                </Stack>
            </div>
            
        </div>
          }catch{
            console.log("mmd");
          }
    }
  }

export default AdminPage